import { AreaPageListing, AreaPageListing_ListingType, AreaPageListing_Provider, AreaPageListing_Status } from '@zoocasa/go-search';
import { capitalizeWords } from '@zoocasa/node-kit/strings/capitalize';
import { AgreementTypes } from 'types/agreement-storage-types';
import { VOW_PROVIDERS } from 'utils/listing-agreement-helper';
import { cleanDashedString } from 'data/addresses';

import type { ListingCardData } from 'components/listing-card';


export class AreaPageListingCardData implements ListingCardData {
  constructor(private listing: AreaPageListing) {}

  get id() {
    return this.listing.id;
  }

  get imageUrl() {
    if (this.hasImageRootStorageKey()) {
      if (this.hasImageSource()) {
        return this.listing.imageRootStorageKey;
      }
      return `https://cdn0.zoocasa.com/${this.listing.imageRootStorageKey}.jpg`;
    }
    return '';
  }

  get isImageReady() {
    return this.hasImageRootStorageKey();
  }

  get imageDesc() {
    return this.listing.address;
  }

  get addressPathWithFallback() {
    return cleanDashedString(this.listing.addressUrlAbsolutePath || this.listing.listingUrlAbsolutePath);
  }

  get isCrea () {
    return this.listing.providerId == AreaPageListing_Provider.CreaDdf;
  }

  get isItsoVow () {
    return this.listing.providerId == AreaPageListing_Provider.ItsoVow;
  }

  get isPillar9Vow () {
    return this.listing.providerId == AreaPageListing_Provider.Pillar9Vow;
  }

  get isRebgvVow () {
    return this.listing.providerId == AreaPageListing_Provider.RebgvVow;
  }

  get isTrebIdxInactive() {
    return this.listing.providerId === AreaPageListing_Provider.TrebIdx && !this.isAvailable;
  }

  get isStellarMls() {
    return this.listing.providerId === AreaPageListing_Provider.StellarMlsIdx;
  }

  get agreementType() {
    if (this.isPillar9Vow){
      return AgreementTypes.PILLAR9_VOW;
    } else if (this.isRebgvVow){
      return AgreementTypes.REBGV_VOW;
    } else if (this.isItsoVow){
      return AgreementTypes.ITSO_VOW;
    } else if (this.isCrea){
      return AgreementTypes.CREA;
    }
  }

  get isSignInAndTermsOfUseRequired() {
    return this.listing.termsOfUseRequired ? true : false;
  }

  get mlsNum() {
    return this.listing.mls;
  }

  get brokerage() {
    return this.listing.brokerage;
  }

  public get providerId() {
    return this.listing.providerId;
  }

  public get city() {
    return this.listing.subDivision;
  }
  
  public get province() {
    return this.listing.province;
  }

  public get isPrivate() {
    return this.isVow || !this.isAvailable;
  }

  get isAvailable() {
    return this.listing.statusId === AreaPageListing_Status.Available;
  }

  get isSold() {
    return this.listing.statusId === AreaPageListing_Status.NotAvailableSold;
  }

  get isSoldWithoutSoldPrice() {
    return this.listing.statusId === AreaPageListing_Status.NotAvailableSold && !this.soldPrice;
  }

  get statusId() {
    return this.listing.statusId;
  }

  public get isVow() {
    return VOW_PROVIDERS.includes(this.listing.providerId as AreaPageListing_Provider);
  }
  
  get position() {
    return null;
  }
 
  getStreet = (isAuthenticated: boolean) => {
    return capitalizeWords(!isAuthenticated && this.isPrivate ? this.listing?.streetName : this.seoStreet);
  };

  get isTreb() {
    return !this.isCrea;
  }

  get soldPrice() {
    return this.listing.SoldPrice > 0 ? this.listing.SoldPrice : null;
  }
  
  get soldAt() {
    return this.listing.soldAt || null;
  }

  get price() {
    return this.listing.price;
  }
  
  get addedAt() {
    return this.listing.createdAt!;
  }

  get updatedOn() {
    return this.listing.updatedOn || null;
  }

  get expiredAt() {
    return this.listing.expiredAt || null;
  }

  get bedrooms() {
    return this.listing.bedrooms;
  }

  get bathrooms() {
    return this.listing.bathrooms;
  }
  
  get avgSqft() {
    let avgSqft;
    if (this.listing.squareFootage) {
      const { gt, gte, lt, lte } = this.listing.squareFootage;
      const min = lt || lte;
      const max = gt || gte;
      if (min && max) {
        avgSqft = Math.ceil((min + max) / 2);
      } else if (min) {
        avgSqft = min;
      } else if (max) {
        avgSqft = max;
      }
    }
    return avgSqft;
  }

  get sqftLabel() {
    let sqftLabel = '';
    if (this.listing.squareFootage) {
      const { gt, gte, lt, lte } = this.listing.squareFootage;
      const min = gt || gte;
      const max = lt || lte;
      let sqft = 'N/A';
    
      // Note: if max sqft is 499 always show 0-499, for anything else show min-max or just min or max when only one available
      sqft = max === 499 ? '0-499' : [min, max].filter(item => item).join('–');

      sqftLabel = `${!sqft?.trim()?.length ? 'N/A' : sqft.trim() } sqft`;
    }
    return sqftLabel;
  }

  get statusLabel() {
    let lastStatusLabel: string | null = null;
    if (this.listing.lastStatus) {
      const lastStatusMappings: Record<string, string> = {
        Bom: 'Back on Market',
        Dft: 'Deal Fell Through',
        Exp: 'Expired',
        Ext: 'Extended',
        Lc: 'Leased Conditionally',
        Lsd: 'Leased',
        New: 'New',
        Pc: 'Price Change',
        Sc: 'Sold Conditionally',
        Sld: 'Sold',
        Sus: 'Suspended',
        Ter: 'Terminated',
      };
      lastStatusLabel = lastStatusMappings[this.listing.lastStatus];
    }
    if (lastStatusLabel && lastStatusLabel !== 'New') {
      return lastStatusLabel;
    } else if (this.isAvailable) {
      return this.listing.listingType === AreaPageListing_ListingType.rent ? 'For Rent' : 'For Sale';
    } else if (this.isSold) {
      return this.listing.listingType === AreaPageListing_ListingType.rent ? 'Leased' : 'Sold';
    } else {
      return 'Inactive';
    }
  }

  private hasImageRootStorageKey() {
    return this.listing?.imageRootStorageKey.length > 0;
  }

  private hasImageSource(){
    return this.listing?.imageSource == 'exp';
  }

  private get seoStreet() {
    const unitNumber = this.listing?.unit;
    const streetAddress = this.streetAddress;
    const seoStreet = unitNumber?.length > 0 ? `${unitNumber} - ${streetAddress}` : streetAddress;
    return seoStreet;
  }

  private get streetAddress() {
    const { streetNumber, streetName } = this.listing;
    return streetNumber ? `${streetNumber} ${streetName}` : streetName;
  }
}