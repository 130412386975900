import React, { ReactNode, useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { buildClassName } from 'utils/build-class-name';
import { loadStylesheet } from 'utils/load-file';
import styles from './style.module.scss';

interface Props {
  title: string;
  isActive: boolean;
  onClick(): void;
  className?: string;
  children: ReactNode;
  testId?: string;
  isDesktop?: boolean;
}

export default function CollapsibleSection({ title, isActive, onClick, className, children, testId, isDesktop }: Props) {
  useEffect(() => {
    const source = 'https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined';
    loadStylesheet(source, document.querySelector('body') as any);
  }, []);

  return (
    <Accordion expanded={isActive} onClick={onClick} className={buildClassName(styles.component, className, isActive && styles.active)}>
      <AccordionSummary
        expandIcon={isDesktop ? <KeyboardArrowDownIcon /> :<ArrowDropDown className={styles.arrow} />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className={styles.header}
        data-testid={testId}
      >
        <h3 className={styles.label}>{title}</h3>
      </AccordionSummary>
      <AccordionDetails>
        <div data-testid={`${testId}Expanded`}>
          {isActive && children}
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
