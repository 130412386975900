const filterDeprecationAckKey = 'filterDeprecationAck';

export function setFilterDeprecationAck(ack: boolean) {
  localStorage.setItem(filterDeprecationAckKey, JSON.stringify(ack));
}

export function hasFilterDeprecationBeenAck() {
  const filterDeprecationAck = localStorage?.getItem(filterDeprecationAckKey);
  if (filterDeprecationAck && filterDeprecationAck.trim().length > 0) {
    return JSON.parse(filterDeprecationAck) as boolean;
  }
  return false;
};

export const clearFilterDeprecationAck = () => { 
  localStorage.setItem(filterDeprecationAckKey, JSON.stringify(false));
};