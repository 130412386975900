import React from 'react';
import styles from './style.module.scss';
import { formatNumberWithSuffix } from 'utils/format-number-with-suffix';
import formatNumber from 'utils/format-number';

interface Props {
  fieldName: string;
  value: number;
  isCurrency?: boolean;
  hasSuffix?: boolean;
  isRatio?: boolean;
}

const SingleDataCard = ({ fieldName, value, isCurrency = false, hasSuffix = false, isRatio = false }: Props) => {
  return (
    <div className={styles.component}>
      <div className={styles['field-name']}>
        {fieldName}
      </div>
      {value!==0 ?
        <div className={styles['value']}>
          {isCurrency && '$'}
          {hasSuffix ?
            formatNumberWithSuffix(value, isCurrency) :
            formatNumber(value)
          }
          {isRatio && '%'}
        </div>
        :
        <div className={styles['value']}>
          {'N/A'}
        </div>
      }
    </div>
  );
};

export default SingleDataCard;
