import React, { useEffect, useState } from 'react';
import styles from './style.module.scss';
import Image from 'next/image';
import CloseIcon from 'components/icon/close-icon';
import useOutsideClickHandler from 'hooks/use-outside-click-handler';
import { useIsMobile } from 'hooks/use-size-class';
import Cookies from 'js-cookie';
import { MLML_LEAD_COOKIE_NAME } from 'constants/cookies';
import { AgentInfo } from 'components/my-link-my-lead-banner';

export default function MyLinkMyLead() {
  const [isDesktopOpen, setIsDesktopOpen] = useState(false);
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const [agentInfo, setAgentInfo] = useState<AgentInfo | null>();
  const isMobile = useIsMobile();
  const title = 'eXp Realty Agent';

  const toggleDesktopPopover = () => {
    if (isMobile) {
      setIsMobileOpen(!isMobileOpen);
    } else {
      setIsDesktopOpen(!isDesktopOpen);
    }
  };

  useEffect(() => { // Check MLML_LEAD_COOKIE_NAME cookie
    const cookieValue = Cookies.get(MLML_LEAD_COOKIE_NAME);
    if (cookieValue) {
      setAgentInfo(JSON.parse(cookieValue));
    }
  }, []);

  const outsideClickHandlerRef = useOutsideClickHandler<HTMLDivElement>(agentInfo ? (isDesktopOpen && toggleDesktopPopover!)! : null);
  const agentPic = agentInfo?.photo ? agentInfo?.photo : '/next/assets/images/agent-search/agent-default-profile-pic.png';
  return (agentInfo &&
    <div className={styles.container}>
      <div className={styles.component} ref={outsideClickHandlerRef}>
        <button className={styles['avatar-button']} onClick={toggleDesktopPopover}>
          <Image src={agentPic} alt={agentInfo?.firstName} className={styles['avatar-small']} width={40} height={40}/>
          <span className={styles['name']}>{agentInfo?.firstName}</span>
        </button>
        {isDesktopOpen && !isMobile && (
          <div className={styles['popover']}>
            <CloseIcon className={styles['close-button']} onClick={toggleDesktopPopover}/>
            <p className={styles['popover-title']}>The search is provided by</p>
            <div className={styles['popover-content']}>
              <Image src={agentPic} alt={agentInfo.firstName} className={styles['avatar-large']} width={65} height={65}/>
              <div className={styles['popover-agent-info']}>
                <p className={styles['agent-name']}>{agentInfo?.firstName} {agentInfo?.lastName}</p>
                <p className={styles['agent-title']}>{title}</p>
              </div>
            </div>
          </div>
        )}
      </div>

      {isMobileOpen && isMobile &&
        <div className={styles['mobile-bottom-popup']}>
          <div className={styles['popover']}>
            <CloseIcon className={styles['close-button']} onClick={toggleDesktopPopover}/>
            <p className={styles['popover-title']}>The search is provided by</p>
            <div className={styles['popover-content']}>
              <Image src={agentPic} alt={agentInfo?.firstName} className={styles['avatar-large']} width={65} height={65}/>
              <div className={styles['popover-agent-info']}>
                <p className={styles['agent-name']}>{agentInfo?.firstName} {agentInfo?.lastName}</p>
                <p className={styles['agent-title']}>{title}</p>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
}
