import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import scrollToSelector from 'utils/scroll-to-selector';
import deepMerge from 'deepmerge';
import { updateSearchParams } from 'utils/update-query-params';

import type { PaginationParams } from 'utils/types';

export default function usePaginatedQuery<P extends Record<string, unknown> & PaginationParams>(paramsWithPage: P, onValueChange: (params: P) => Promise<any>) {
  const [isLoading, setIsLoading] = useState(false);
  const [params, setParams] = useState(paramsWithPage);
  const router = useRouter();

  useEffect(() => {
    updateUrlPageParam(params.page.number);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.page.number]);

  const updateUrlPageParam = (pageNumber: number) => {
    if (router.pathname !== '/search') {
      if (router.pathname === '/[...dynamic]') {
        if (pageNumber > 1 || (pageNumber == 1 && router.query.page && parseInt(router.query.page[0]) > 1)) {
          if (parseInt(router.query.page?.toString() || '') != pageNumber) {
            const currentPath = window.location.pathname;
            const currentQuery = window.location.search;
            const newQuery = updateSearchParams(currentQuery, { page: pageNumber.toString() });
            router.push(currentPath + newQuery, currentPath + newQuery);
          }
        }
      } else {
        const page = { page: pageNumber, size: params.page.size };
        const query = pageNumber > 1 ? page : {};
        router.push({ query }, undefined, { shallow: true });
      }
    }
  };

  const queryData = async (params: P) => {
    setIsLoading(true);
    scrollToSelector('body');
    await onValueChange(params);
    setIsLoading(false);
  };

  const updateParams = (newParams: Partial<P>) => {
    if (!newParams.page) {
      newParams = { ...newParams, page: { number: 1 }};
    }
    const updatedParams = deepMerge(params, newParams);
    setParams(updatedParams);
    queryData(updatedParams);
  };

  return { isLoading, params, updateParams };
}
