import React from 'react';
import styles from './style.module.scss';
import { formatNumberWithSuffix } from 'utils/format-number-with-suffix';
import type { InsightsResponse } from '@zoocasa/go-search';
import { buildClassName } from 'utils/build-class-name';
import { useThemeContext } from 'contexts';
import { ThemeNames } from 'types/themes';

interface Props {
  data: InsightsResponse;
}


const AreaData = ({ data }: Props) => {
  const { themeName } = useThemeContext();
  const isExpTheme = themeName && themeName !== ThemeNames.ZOOCASA;

  return (
    <div className={styles.component}>
      <div className={buildClassName(styles['column'], isExpTheme && styles['exp-theme'])}>
        <div className={buildClassName(styles['icon-container'], isExpTheme && styles['exp-theme'])}>
          <span className="material-symbols-outlined">house</span>
        </div>
        <div className={styles['sub-section']}>
          <div className={styles['number']}>{data.TotalHouses || 'N/A'}</div>
          <div className={styles['text']}>Detached</div>
        </div>
        <div className={styles['sub-section']}>
          <div className={styles['number']}>
            {data.AvgHousePrice ?
              '$' + formatNumberWithSuffix(data.AvgHousePrice) :
              'N/A'
            }
          </div>
          <div className={styles['text']}>Average Price</div>
        </div>
      </div>

      <div className={styles['column']}>
        <div className={buildClassName(styles['icon-container'], isExpTheme && styles['exp-theme'])}>
          <span className="material-symbols-outlined">corporate_fare</span>
        </div>
        <div className={styles['sub-section']}>
          <div className={styles['number']}>{data.TotalTownhouses || 'N/A'}</div>
          <div className={styles['text']}>Townhouse</div>
        </div>
        <div className={styles['sub-section']}>
          <div className={styles['number']}>
            {data.AvgTownhousePrice ?
              '$' + formatNumberWithSuffix(data.AvgTownhousePrice) :
              'N/A'
            }
          </div>
          <div className={styles['text']}>Average Price</div>
        </div>
      </div>

      <div className={buildClassName(styles['column'], isExpTheme && styles['exp-theme'])}>
        <div className={buildClassName(styles['icon-container'], isExpTheme && styles['exp-theme'])}>
          <span className="material-symbols-outlined">apartment</span>
        </div>
        <div className={styles['sub-section']}>
          <div className={styles['number']}>{data.TotalCondos || 'N/A'}</div>
          <div className={styles['text']}>Condo</div>
        </div>
        <div className={styles['sub-section']}>
          <div className={styles['number']}>
            {data.AvgCondoPrice ?
              '$' + formatNumberWithSuffix(data.AvgCondoPrice) :
              'N/A'
            }
          </div>
          <div className={styles['text']}>Average Price</div>
        </div>
      </div>
    </div>
  );
};

export default AreaData;
