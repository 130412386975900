import React, { useState, useEffect, useContext } from 'react';
import styles from './style.module.scss';
import { buildClassName } from 'utils/build-class-name';
import formatCurrency, { removeCommasFromPrice } from 'utils/format-currency';
import TextField from 'components/control/text-field';
import Button from 'components/control/button';
import { DropdownContext, IDropdownContext } from 'contexts/dropdown';
import { searchFilterIds } from 'constants/test-constants';
import { formatNumber } from '@zoocasa/node-kit/numbers';
import { convertFormattedNumberToInt, isStringNumeric } from 'utils/format-number';
import { defaultBuyPriceOptions, defaultRentPriceOptions } from 'utils/select-options';

interface Props {
  min: number | null;
  max: number | null;
  onValueChange: (min: number | null, max: number | null) => void;
  isRental: boolean;
  theme?: 'dark' | 'light';
}

const MAX = 'max' as const;
const MIN = 'min' as const;
type PriceType = 'max' | 'min';

export default function FilterDropdownPriceRange({ min, max, onValueChange, isRental, theme }: Props) {
  const [minPrice, setMinPrice] = useState(min?.toString());
  const [maxPrice, setMaxPrice] = useState(max?.toString());
  const [activeType, setActiveType] = useState('');

  const { closeDropdown, isActive } = useContext(DropdownContext) as IDropdownContext;

  const priceOptions = isRental ? defaultRentPriceOptions : defaultBuyPriceOptions;

  useEffect(() => {
    setPrice(max?.toString() || '', MAX);
    setPrice(min?.toString() || '', MIN);
  }, [min, max]);

  const applyPriceFilter = () => {
    if (isActive) {
      onValueChange(convertFormattedNumberToInt(minPrice), convertFormattedNumberToInt(maxPrice));
      closeDropdown();
    }
  };

  const setPrice = (price: string | undefined, type: PriceType) => {
    if (isStringNumeric(removeCommasFromPrice(price))) {
      const formattedPrice = formatNumber(convertFormattedNumberToInt(price));
      if (type === MAX) setMaxPrice(formattedPrice);
      if (type === MIN) setMinPrice(formattedPrice);
    } else if (price === '') {
      if (type === MAX) setMaxPrice('');
      if (type === MIN) setMinPrice('');
    }
  };

  return (
    <div className={buildClassName(styles.component, theme && styles[theme])}>
      <div className={styles['price-inputs']}>
        <div className={styles['input-container']}>
          <TextField
            placeholder="No Min"
            testId={searchFilterIds.priceFilterMinInput}
            value={minPrice || ''}
            onValueChange={value => setPrice(value, MIN)}
            onClick={() => setActiveType(MIN)}
            containerClassName={styles['custom-input-container']}
          />
          <NumberDropdownOptionList
            isActive={activeType === MIN}
            isCurrency={true}
            options={priceOptions}
            setPrice={setPrice}
            type={MIN}
            setActiveType={setActiveType}
            value={minPrice}
            maxValue={maxPrice}
          />
        </div>
        <small>–</small>
        <div className={styles['input-container']}>
          <TextField
            placeholder="No Max"
            testId={searchFilterIds.priceFilterMaxInput}
            value={maxPrice || ''}
            onValueChange={value => setPrice(value, MAX)}
            onClick={() => setActiveType(MAX)}
            containerClassName={styles['custom-input-container']}
          />
          <NumberDropdownOptionList
            isActive={activeType === MAX}
            isCurrency={true}
            options={priceOptions}
            setPrice={setPrice}
            type={MAX}
            setActiveType={setActiveType}
            value={maxPrice}
            minValue={minPrice}
          />
        </div>
      </div>
      <Button label="Apply" className={styles['filter-controls']} theme={'primary'} onClick={applyPriceFilter} testId={searchFilterIds.priceRangeDropdownApply}/>
    </div>
  );
}

interface PriceOptionsProps {
  options: number[];
  isCurrency: boolean;
  setPrice: (value: string, type: PriceType) => void;
  value: string | undefined;
  type: PriceType;
  setActiveType: (minOrMax: PriceType | '') => void;
  isActive: boolean;
  minValue?: string;
  maxValue?: string;
  isUpSideDown?: boolean;
}

export const NumberDropdownOptionList = ({ options, isCurrency, setPrice, value, type, setActiveType, isActive, minValue, maxValue, isUpSideDown = false }: PriceOptionsProps) => {
  // get rid of inapplicable options
  const getFormattedOptions = () => {
    let formattedOptions;
    if (type === MIN && maxValue) {
      formattedOptions = options.filter(option => option > 0 && option < (convertFormattedNumberToInt(maxValue))).slice(0, 10);
    } else if (type === MAX && minValue) {
      formattedOptions = options.filter(option => option > 0 && option > (convertFormattedNumberToInt(minValue) || 0)).slice(0, 10);
    } else {
      formattedOptions = options.slice(0, 10);
    }
    return isUpSideDown ? formattedOptions.reverse() : formattedOptions;
  };

  return (
    <div className={buildClassName(styles['price-options'], isActive && styles.active, isUpSideDown && styles['up-side-down'])} data-testid={type === MIN ? searchFilterIds.minPriceFilterOptions : searchFilterIds.maxPriceFilterOptions}>
      {isUpSideDown &&
        <span
          key={'any'}
          onClick={() => {
            setPrice('', type);
            setActiveType('');
          }}
        >
          Any {isCurrency && 'Price'}
        </span>
      }
      {getFormattedOptions().map(option => (
        <span
          key={option}
          className={buildClassName(option === parseInt(value || '') && styles['active-option'])}
          onClick={() => {
            setPrice(option.toString(), type);
            setActiveType('');
          }}
        >
          {isCurrency ? formatCurrency(option) : formatNumber(option)}
        </span>
      ))}
      {!isUpSideDown &&
        <span
          key={'any'}
          onClick={() => {
            setPrice('', type);
            setActiveType('');
          }}
        >
          Any {isCurrency && 'Price'}
        </span>
      }
    </div>
  );
};
