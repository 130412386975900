import React, { useEffect, useState } from 'react';
import { getProviderInfo } from 'utils/providers';
import { ProvinceOrStateCode, isUsStateCode } from 'utils/province_or_state';
import formatDate from 'utils/format-date';
import { buildClassName } from 'utils/build-class-name';
import BrokerageLogos from 'components/brokerage-logos';
import styles from './style.module.scss';
import { useThemeContext } from 'contexts/theme';

import type Listing from 'data/listing';

type DisclaimerInfo = {
  disclaimer: {
    Disclaimer: string;
    CourtesyOf: string;
    ManagingBroker: string;
  };
};

interface Props {
  provider: Listing['providerId'];
  listing?: Listing;
}

export default function ProviderDisclaimer({ provider, listing }: Props) {
  const [info, setInfo] = useState<DisclaimerInfo>();
  const isListing = !!listing;
  const isAmericanListing = isListing && isUsStateCode(listing.province?.toLowerCase() as ProvinceOrStateCode);
  const { themeName } = useThemeContext();

  const grabDisclaimerInfo = async () => {
    setInfo(await getProviderInfo(provider, themeName, listing));
  };

  useEffect(() => {
    grabDisclaimerInfo();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!info || info && !('disclaimer' in info)) return null;

  return (
    <div className={buildClassName(styles.component, !isListing && styles['area-disclaimer'])}>
      {isListing && isAmericanListing && !!info.disclaimer.CourtesyOf.length && <p className={styles.courtesy}>{info.disclaimer.CourtesyOf}</p>}
      {isListing && !isAmericanListing && listing.brokerage && <p className={styles.courtesy}>Listed by: {listing.brokerage}</p>}
      {isListing && listing.isVancouverFeed && <p className={styles.courtesy}>{listing.brokerageEmail} {listing.brokeragePhone}</p>}
      <BrokerageLogos providerId={provider} listing={listing} />
      <div className={styles.disclaimer}>
        <div dangerouslySetInnerHTML={{ __html: info.disclaimer.Disclaimer }} />
      </div>

      {info.disclaimer.ManagingBroker && <p className={styles.courtesy}>{info.disclaimer.ManagingBroker}</p>}

      {isAmericanListing && listing.updatedOn &&
        <p>Data last updated at: {formatDate(listing.updatedOn)}</p>
      }
    </div>
  );
}
