import React from 'react';
import ImageLoader from 'components/image-loader';
import { ProvinceOrStateCode, isUsStateCode } from 'utils/province_or_state';
import styles from './style.module.scss';

import type Listing from 'data/listing';

interface Props {
  providerId: Listing['providerId'];
  listing?: Listing;
}

export default function BrokerageLogos({ providerId, listing }: Props) {
  const isListing = !!listing;
  const isAmericanListing = !isListing || isUsStateCode(listing.province?.toLowerCase() as ProvinceOrStateCode);
  const mlsSource = isAmericanListing ? '/assets/images/mls-logos/mls-grid.svg' : '/assets/images/listing-page/logo-mls.svg';

  const realtorLogo = () => {
    if (isAmericanListing) {
      return <ImageLoader className={styles['provider-logo']} src={`https://zoocasa.s3.amazonaws.com/provider-logos/PROV_LOGO_${providerId}.png`} alt="MLS logo" />;
    } else if (listing.isCrea) {
      const url = listing.url ? listing.url.startsWith('https://') ? listing.url : `https://${listing.url}` : 'https://www.realtor.ca/en';
      return (
        <a href={url} target="_blank" rel="noreferrer" className={styles['crea-logo']}>
          <ImageLoader src="/next/assets/images/powered_by_realtor.svg" alt="Powered by: REALTOR.ca" width="3.6em" height="3.6em" />
        </a>
      );
    } else {
      return <ImageLoader src="/assets/images/listing-page/logo-realtor.svg" alt="Realtor logo" className={styles['realtor-logo']} />;
    }
  };

  return (
    <div className={styles.component}>
      {realtorLogo()}
      {isListing && <ImageLoader src={mlsSource} alt="MLS logo" width="3.6em" height="25px" className={styles['mls-logo']}/>}
    </div>
  );
}
