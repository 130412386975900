import React from 'react';
import { buildClassName } from 'utils/build-class-name';
import Select from '@zoocasa/node-kit/components/controls/base/select';
import NibIcon from 'components/icon/nib-icon';
import styles from './style.module.scss';
import ReactSelect, { SingleValue } from 'react-select';

import type { SelectProps } from '@zoocasa/node-kit/components/controls/base/select';
import type { SelectOptionValue } from '@zoocasa/node-kit/components/controls/types';

type Props<T extends SelectOptionValue> = SelectProps<T> & {
  label?: string;
  testId?: string;
  ariaLabel?: string;
  isExpTheme?: boolean;
};

export default function SelectField<T extends SelectOptionValue>({ label, isExpTheme, testId, ariaLabel, className, ...selectProps }: Props<T>) {
  const containerClassName = buildClassName(className, styles.component);
  const { onValueChange, options, value } = selectProps;
  const selectedValue = options.find(option => option.value === value);
  // 0c0f24 darknavy
  return isExpTheme ? (
    <ReactSelect
      className={buildClassName(styles.exp, 'react-select-container')}
      styles={{
        container: (baseStyles, state) => ({
          ...baseStyles,
          borderColor: state.isFocused ? '#5a5a5a' : '#dedede',
          '&:hover': {
            borderColor: '#5a5a5a',
          },
        }),
        control: (baseStyles, state) => ({
          ...baseStyles,
          borderRadius: '100px',
          height: '50px',
          boxShadow: 'none',
          borderColor: state.isFocused || state.menuIsOpen ? '#5a5a5a' : '#dedede',
          '&:hover': {
            borderColor: '#5a5a5a',
          },
        }),
        option: (baseStyles, state) => ({
          ...baseStyles,
          color: '#000',
          background: state.isFocused || state.isSelected ? '#f3f3f4': '#fff',
        }),
        indicatorSeparator: baseStyles => ({
          ...baseStyles,
          backgroundColor: 'unset',
        }),
        dropdownIndicator: baseStyles => ({
          ...baseStyles,
          width: '28px',
          height: '45px',
          color: '#000',
        }),
        singleValue: baseStyles => ({
          ...baseStyles,
          height: '50px',
          paddingTop: '12px',
        }),
        placeholder: baseStyles => ({
          ...baseStyles,
          height: '50px',
          paddingTop: '12px',
        }),
      }}
      options={options.map(option => ({ label: option.label, value: option.value }))}
      isSearchable={false}
      value={selectedValue}
      onChange={(selectedOption: SingleValue<{ label: string; value: T }>) => {
        if (onValueChange && selectedOption) {
          onValueChange(selectedOption.value);
        }
      }}
    />
  ) : (
    <div className={containerClassName} data-testid={testId}>
      {label && <label htmlFor={label}>{label}</label>}
      <Select
        id={label}
        {...selectProps}
        aria-label={ariaLabel}
        className={buildClassName(styles.select, selectProps['aria-invalid'] && styles.wrong)}
      />
      <NibIcon />
    </div>
  );
}
