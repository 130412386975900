import React, { useMemo, useState } from 'react';
import FilterDropdown, { DARK_THEME, FilterDropdownTheme } from 'components/advanced-search/filter-dropdown';
import ButtonGroup from 'components/control/button-group';
import Checkbox from 'components/control/checkbox';
import { searchFilterIds } from 'constants/test-constants';
import { bedroomBathroomParkingButtonGroupOptions } from 'utils/select-options';
import { parameterContainsIsMore, toggleContainsMore } from 'utils/listing-query-helper';

import type { Bedroom } from 'contexts/preferences/listing-params/types';

//#region Constants
const BedroomsRegex = new RegExp(/^[0-6]\+?$/);
//#endregion


//#region Types
export type Bedrooms = `${Bedroom}` | `${Bedroom}+`;

export interface BedroomsFilterDropdownProps {
    bedrooms: Bedrooms;
    theme?: FilterDropdownTheme;
    onValueChange(value: string): void;
}
//#endregion

const BedroomsFilterDropdown = ({ bedrooms, onValueChange, theme = DARK_THEME }: BedroomsFilterDropdownProps) => {
  const sanitizedBedrooms = useMemo(() => {
    return BedroomsRegex.test(bedrooms) ? bedrooms : '0+';
  }, [bedrooms]);

  const [isExactBedroomNumber, setIsExactBedroomNumber] = useState(!parameterContainsIsMore(sanitizedBedrooms));
  const bedroomNumberOptions = bedroomBathroomParkingButtonGroupOptions(isExactBedroomNumber);

  const bedroomsFilterLabel = useMemo(() => {
    return `${sanitizedBedrooms} Beds`;
  }, [sanitizedBedrooms]);

  return (
    <FilterDropdown
      theme={theme}
      label={bedroomsFilterLabel}
      testId={searchFilterIds.bedsDropdownMap}
    >
      <>
        <ButtonGroup
          options={bedroomNumberOptions}
          value={sanitizedBedrooms}
          onValueChange={onValueChange}
          size={'small'}
        />
        <Checkbox
          label="Use exact number"
          name="beds"
          value={isExactBedroomNumber}
          onValueChange={checked => {
            if (sanitizedBedrooms !== '0+') {
              onValueChange(toggleContainsMore(bedrooms));
            }
            setIsExactBedroomNumber(checked);
          }}
          testId={searchFilterIds.bedsCheckboxSavedSearches}
        />
      </>
    </FilterDropdown>
  );
};

export default BedroomsFilterDropdown;