import { useEffect } from 'react';

export default function useKeyboard(callback: () => void, ...keys: string[]) {
  useEffect(() => {
    document.body.addEventListener('keydown', handler, { passive: true });
    return () => document.body.removeEventListener('keydown', handler);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callback]);

  const handler = (event: KeyboardEvent) => {
    keys.forEach(key => {
      const keyParts = key.toLowerCase().split(/\s*\+\s*/);
      const matches: boolean[] = [];
      if (!event.key) {
        return;
      }
      keyParts.forEach(part => {
        const clickedKey = event.key.toLowerCase();
        if (['shift', '⇧'].includes(part)) {
          matches.push(event.shiftKey);
        } else if (['alt', 'option', '⌥'].includes(part)) {
          matches.push(event.altKey);
        } else if (['command', '⌘'].includes(part)) {
          matches.push(event.metaKey);
        } else if (['control', 'ctrl', '^'].includes(part)) {
          matches.push(event.ctrlKey);
        } else if (part === 'esc') {
          matches.push(clickedKey === 'escape');
        } else {
          matches.push(clickedKey === part);
        }
      });
      if (matches.filter(match => !match).length === 0) {
        callback();
      }
    });
  };
}
