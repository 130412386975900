import React from 'react';
import FilterDropdown, { DARK_THEME, FilterDropdownTheme } from 'components/advanced-search/filter-dropdown';
import ButtonGroup from 'components/control/button-group';
import { listingTypeSelectionOptions } from 'utils/select-options';
import { searchFilterIds } from 'constants/test-constants';

//#region Types
export interface BuyOrRentFilterDropdownProps {
    rental: boolean;
    theme?: FilterDropdownTheme;
    onValueChange(value: boolean): void;
}
//#endregion

const BuyOrRentFilterDropdown = ({ rental, onValueChange, theme = DARK_THEME }: BuyOrRentFilterDropdownProps) => {
  return (
    <FilterDropdown
      theme={theme}
      label={rental ? 'Rent' : 'Buy'}
      testId={searchFilterIds.buyOrRentDropdownMap}
    >
      <ButtonGroup
        options={listingTypeSelectionOptions}
        value={rental || false}
        onValueChange={onValueChange}
        size='large'
      />
    </FilterDropdown>
  );
};

export default BuyOrRentFilterDropdown;