import React, { ReactElement, useContext } from 'react';
import useOutsideClickHandler from 'hooks/use-outside-click-handler';
import DropdownContextProvider, { DropdownContext } from 'contexts/dropdown';
import NibIcon from 'components/icon/nib-icon';
import { buildClassName } from 'utils/build-class-name';
import styles from './style.module.scss';
import { useThemeContext } from 'contexts';
import { ThemeNames } from 'types/themes';

import type { IDropdownContext } from 'contexts/dropdown';

//#region Types
export const LIGHT_THEME = 'light';
export const DARK_THEME = 'dark';
export const ROUNDED_THEME = 'rounded';

export type FilterDropdownTheme = typeof LIGHT_THEME | typeof DARK_THEME | typeof ROUNDED_THEME;

export interface FilterDropdownProps {
  label: string;
  children: ReactElement;
  theme?: FilterDropdownTheme;
  className?: string;
  contentClassName?: string;
  testId?: string;
}
//#endregion

export default function FilterDropdown({ label, children, theme = LIGHT_THEME, className, contentClassName, testId }: FilterDropdownProps) {
  return (
    <DropdownContextProvider>
      <Dropdown label={label} theme={theme} className={className} contentClassName={contentClassName} testId={testId}>
        {children}
      </Dropdown>
    </DropdownContextProvider>
  );
}

function Dropdown({ label, children, theme, className, contentClassName, testId }: FilterDropdownProps) {
  const { isActive, closeDropdown, toggleDropdown } = useContext(DropdownContext) as IDropdownContext;
  const { themeName } = useThemeContext();
  const isExpTheme = themeName && themeName !== ThemeNames.ZOOCASA;

  return (
    <div
      className={buildClassName(styles.component, isActive && styles.active, styles[`theme-${theme}`], isExpTheme && styles['exp-theme'], className)}
      ref={useOutsideClickHandler(closeDropdown)}
      data-testid={testId}
    >
      <div className={styles.dropdown} onClick={toggleDropdown} data-testid={`${testId}Label`}>
        {label} <NibIcon />
      </div>
      <div className={buildClassName(styles.panel, styles[`theme-${theme}`], contentClassName)}>
        {children}
      </div>
    </div>
  );
}
