import { TopCitiesResponse, ProvincesResponse } from '@zoocasa/go-search';
import { CountryCode } from 'types/countries';
import configJSON from 'config.json';
import replaceFrenchAccents from 'utils/replace-french-accents';
import { fetchWithRetry } from 'utils/fetchWithRetry';
import { ProvinceOrStateCode, provinceOrStateNameFromCode } from 'utils/province_or_state';

export async function getCities(country: CountryCode, province?: string, prefixChar?: string) {
  try {
    const prefix = prefixChar && prefixChar !== 'popular' && prefixChar.length === 1 ? prefixChar : ''; 
    const query = prefix.length > 0 ? `?prefix=${prefix}&limit=9000` : '?limit=9000';
    const provincePath = province ? `/${province}` : '';
    const citiesUrl = `${configJSON.goSearchClientSideHost}/insights/popular/${country}${provincePath}${query}`;
    const response = await fetchWithRetry(citiesUrl, { method: 'GET' });
    if (!response.ok) {
      console.error('Failed to fetch cities: %d %s', response.status, response.statusText);
      return [];
    } 
    const content = await response.blob();
    const buffer = await content.arrayBuffer();
    const cities = TopCitiesResponse.decode(new Uint8Array(buffer)).topCities;
    return cities.map(place => {
      const formattedSlug = replaceFrenchAccents(place.Slug);
      return {
        name: place.Name,
        link: `/${formattedSlug}-real-estate`,
        label: place.TotalCount,
      };
    });
  } catch (error: any) {
    console.error('Failed to fetch cities: %s', error);
    return [];
  }
}

export async function getProvinces(country: CountryCode) {
  try {
    const provincesUrl = `${configJSON.goSearchClientSideHost}/insights/provinces/${country}`;
    const response = await fetchWithRetry(provincesUrl, { method: 'GET' });
    if (!response.ok) {
      console.error('Failed to fetch provinces: %d %s', response.status, response.statusText);
      return [];
    } 
    const content = await response.blob();
    const buffer = await content.arrayBuffer();
    const provinces = ProvincesResponse.decode(new Uint8Array(buffer)).provinces;
    return provinces.map(place => {
      const provinceName = provinceOrStateNameFromCode(place.Province.toLowerCase() as ProvinceOrStateCode);
      const formattedProvinceSlug = provinceName.toLowerCase().replace(/ /g, '-');
      return {
        name: `${provinceName} (${place.Province})`,
        link: `/${formattedProvinceSlug}-real-estate`,
        label: place.TotalCount,
      };
    });
  } catch (error: any) {
    console.error('Failed to fetch provinces: %s ', error);
    return [];
  }
}