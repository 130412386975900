import React from 'react';
import CollapsibleSection from 'components/collapsible-section';

import styles from './style.module.scss';
import useActive from 'hooks/use-active';

interface MobileInternalLinkProps {
    index: number;
    isActive: boolean;
    title: string;
    links: JSX.Element[];
  }
  
const MobileInternalLink = ({ index, isActive = false, title, links }: MobileInternalLinkProps) => {
  const [isExpanded, toggle] = useActive(isActive);
  return (
    <div key={index} className={styles['footer-group-area-page']}>
      <CollapsibleSection title={title} isActive={isExpanded} onClick={toggle} className={styles['links-collapsable']}>
        <div className={styles['links-container']}>{links}</div>
      </CollapsibleSection>
      <div className={styles['row-border']}></div>
    </div>
  );
};

export default MobileInternalLink;