import configJSON from 'config.json';
import { HttpRequestMethodTypes } from 'types';
import { fetchWithRetry } from 'utils/fetchWithRetry';
import { ThemeName } from 'themes';

import type Listing from 'data/listing';

const isLocal = process.env.NODE_ENV === 'development';

export async function getProviderInfo(providerId: Listing['providerId'], siteTheme: ThemeName, listing?: Listing) {
  try {
    // If the providerTermsOfUseHost is not the staging URL & the NODE_ENV is dev, use local compliance service path
    const baseURL = isLocal && configJSON.providerTermsOfUseHost !== 'https://stage.zoocasa.com' ? `${configJSON.providerTermsOfUseHost}/v1/providers/disclaimers` : `${configJSON.providerTermsOfUseHost}/services/providers-compliance/v1/providers/disclaimers`;
  
    const responseBlob = await fetchWithRetry(`${baseURL}/${providerId}/${siteTheme}`, {
      method: HttpRequestMethodTypes.POST,
      body: JSON.stringify(listing),
    });
    const response = await responseBlob.json();
    return response;
  } catch (error: any) {
    console.error('Failed to fetch provider disclaimer: %s', error);
    return null;
  }
}