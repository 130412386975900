import React from 'react';

const useActive = (initialState: boolean): [boolean, () => void] => {
  const [isActive, setIsActive] = React.useState<boolean>(initialState);
  const toggle = React.useCallback(
    () => setIsActive(isToggled => !isToggled),
    [setIsActive]
  );
    
  return [isActive, toggle];
};

export default useActive;