import React, { useContext, useEffect, useState } from 'react';
import Button, { SECONDARY_THEME, TERTIARY_THEME } from 'components/control/button';
import MapIcon from 'components/icon/map-icon';
import ListViewIcon from 'components/icon/list-view-icon';
import { mapPageIds } from 'constants/test-constants';
import { trackEvent } from 'utils/google-tag-manager';
import { IPreferences, PreferencesContext } from 'contexts';
import getTargetedUrl from '../targeted-url-helper';
import { cloneDeep } from 'lodash';
import { ignoredFilters } from '../area_listings_page_view_controller';
import { useExperiment } from '@growthbook/growthbook-react';
import styles from './style.module.scss';
import { isClientSide } from 'utils/host-config';

//#region Constants
export const MAP_VIEW = 'MapView';
export const LIST_VIEW = 'ListView';
//#endregion

//#region Types
export type ViewType = typeof MAP_VIEW | typeof LIST_VIEW;

export interface MapListToggleButtonProps {
  slug: string | null;
  viewType: ViewType;
  onClick(): void;
  isMobile: boolean;
}
//#endregion

const MapListToggleButtonOnMap = ({ slug, viewType, onClick, isMobile }: MapListToggleButtonProps) => {
  const { listingParams } = useContext(PreferencesContext) as IPreferences;
  const abTestingFiltersButton = useExperiment({ key: 'more-filters', variations: [0, 1]});
  const mapViewButtonStyle = abTestingFiltersButton.variationId === 1 ? TERTIARY_THEME : SECONDARY_THEME;
  const MapViewLabel = isMobile? 'Map' : 'Map View';
  const ListViewLabel = isMobile? 'List' : 'List View';

  function isMapViewType() {
    return viewType === MAP_VIEW;
  }

  const [origin, setOrigin] = useState('');
  useEffect(() => {
    // Check if window is available (client-side)
    if (isClientSide()) {
      setOrigin(window.location.origin);
    }
  }, []);

  const trackEventForToggle = () =>
    trackEvent(isMapViewType() ? 'UiSrchQfMapButClk' : 'UiSrchQfListButClk');

  if (slug && origin) { // go to the area page
    const cookieFiltersClone = cloneDeep(listingParams.filter);
    ignoredFilters.forEach(key => {
      delete cookieFiltersClone[key];
    });
    const targetedUrl = getTargetedUrl({
      url: `${origin}/${slug}-real-estate`,
      filters: cookieFiltersClone,
      sort: listingParams.sort,
      pageNumber: 1,
    });
    return (
      <a
        href={targetedUrl.href}
        onClick={trackEventForToggle}
      >
        <Button
          label={isMapViewType() ? MapViewLabel : ListViewLabel}
          Icon={isMapViewType() ? MapIcon : ListViewIcon}
          theme={mapViewButtonStyle}
          testId={mapPageIds.mapListToggleButton}
          className={isMobile ? styles['mobile-component'] : ''}
        />
      </a>
    );
  } else {
    return (
      <Button
        label={isMapViewType() ? MapViewLabel : ListViewLabel}
        Icon={isMapViewType() ? MapIcon : ListViewIcon}
        onClick={() => {
          trackEventForToggle();
          onClick();
        }}
        theme={mapViewButtonStyle}
        testId={mapPageIds.mapListToggleButton}
        className={isMobile ? styles['mobile-component'] : ''}
      />
    );
  }
};

export default MapListToggleButtonOnMap;
