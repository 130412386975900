import React, { MouseEvent } from 'react';

interface Props {
  className?: string;
  onClick?: (event: MouseEvent) => void;
}

const MlmlCreateIcon = ({ className, onClick }: Props) => {
  return (
    <svg className={className} onClick={onClick} xmlns="http://www.w3.org/2000/svg" width="18" height="12" viewBox="0 0 18 12" fill="none">
      <path
        d="M5.66663 4.16667H12.3333V5.83334H5.66663V4.16667ZM15.75 5H17.3333C17.3333 2.7 15.4666 0.833336 13.1666 0.833336H9.83329V2.41667H13.1666C14.5916 2.41667 15.75 3.575 15.75 5ZM2.24996 5C2.24996 3.575 3.40829 2.41667 4.83329 2.41667H8.16662V0.833336H4.83329C2.53329 0.833336 0.666626 2.7 0.666626 5C0.666626 7.3 2.53329 9.16667 4.83329 9.16667H8.16662V7.58334H4.83329C3.40829 7.58334 2.24996 6.425 2.24996 5ZM14.8333 5H13.1666V7.5H10.6666V9.16667H13.1666V11.6667H14.8333V9.16667H17.3333V7.5H14.8333V5Z"
        fill="white"/>
    </svg>
  );
};

export default MlmlCreateIcon;
