import React, { useContext, useState } from 'react';
import styles from './style.module.scss';
import { searchFilterIds } from 'constants/test-constants';
import Button, { PRIMARY_THEME } from 'components/control/button';
import MlmlCreateIcon from 'components/icon/mlml-create-icon';
import { IModalContext, ModalContext, MY_LINK_MY_LEAD_CREATE_MODAL } from 'contexts';
import { useIsTablet } from 'hooks/use-size-class';
import Checkbox from 'components/control/checkbox';
import { MLML_AGENT_TOOLTIP_COOKIE_NAME } from 'constants/cookies';
import Cookies from 'js-cookie';
import { buildClassName } from 'utils/build-class-name';
import Listing from 'data/listing';

interface Props {
  className?: string;
  buttonClassName?: string;
  isFloating?: boolean;
  overrideUrl?: string;
  listing?: Listing;
  isVisible?: boolean;
}

export default function MyLinkMyLeadCreate({ className, buttonClassName, isFloating, overrideUrl, listing, isVisible = true }: Props) {
  const { openModal } = useContext(ModalContext) as IModalContext;
  const [isOpen, setIsOpen] = useState(!Cookies.get(MLML_AGENT_TOOLTIP_COOKIE_NAME));
  const [donotShow, setDonotShow] = useState(false);
  const isAbbreviation = useIsTablet();

  const handleCreate = () => {
    openModal(MY_LINK_MY_LEAD_CREATE_MODAL, { overrideUrl, listing });
  };

  const handleDismiss = () => {
    setIsOpen(false);
    donotShow && Cookies.set(MLML_AGENT_TOOLTIP_COOKIE_NAME, 'true');
  };

  return (
    <div className={buildClassName(styles.component, isFloating && styles['floating'], className, isVisible && styles['not-visible'])}>
      <Button
        className={buildClassName(styles.button, buttonClassName)}
        theme={PRIMARY_THEME}
        label={isAbbreviation ? 'Create Link' : 'Create Link for My Link My Lead'}
        Icon={MlmlCreateIcon}
        onClick={handleCreate}
        testId={searchFilterIds.saveSearchButtonMapPage}
      />
      {isOpen &&
        <div className={styles['popover']}>
          <h2>Welcome to My Link My Lead (MLML)</h2>
          <p>Create a search to share and ensure leads come directly to you:</p>
          <p>1. Enter a location</p>
          <p>2. Filter by price, beds, baths, or any other criteria</p>
          <p>3. Click the Create Link button to generate a personalized, shareable link!</p>
          <br/>
          <Button
            className={styles.button}
            theme={PRIMARY_THEME}
            label={'Got It'}
            onClick={handleDismiss}
            testId={searchFilterIds.saveSearchButtonMapPage}
          />
          <Checkbox
            label={'Do not show this again.'}
            name='termsAccepted'
            value={donotShow}
            onValueChange={setDonotShow}
          />
        </div>
      }
    </div>
  );
}
