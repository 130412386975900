import type { NamedContent } from 'components/dynamic-page/new-area-listings-page/guides';

//#region Constants
export const CITY_GUIDE_NAMED_CONTENT_TYPE = 'city-guide';
export const INFRASTRUCTURE_NAMED_CONTENT_TYPE = 'infrastructure';
export const TAX_GUIDE_NAMED_CONTENT_TYPE = 'tax-guide';

const splitContentIndex = {
  [CITY_GUIDE_NAMED_CONTENT_TYPE]: 0,
  [INFRASTRUCTURE_NAMED_CONTENT_TYPE]: 1,
  [TAX_GUIDE_NAMED_CONTENT_TYPE]: 2,
};
//#endregion

//#region Types
export type NamedContentType = typeof CITY_GUIDE_NAMED_CONTENT_TYPE | typeof INFRASTRUCTURE_NAMED_CONTENT_TYPE | typeof TAX_GUIDE_NAMED_CONTENT_TYPE;

interface Props {
  namedContent?: NamedContent;
  type: NamedContentType;
}
//#endregion

export default function NamedContent({ namedContent, type }: Props) {
  const html = namedContent?.[splitContentIndex[type]];

  return (
    <div className='named-content'>
      {html && <div dangerouslySetInnerHTML={{ __html: html }} />}
    </div>
  );
}
