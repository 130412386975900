import React from 'react';
import InternalLink from './InternalLink';
import MobileInternalLink from './mobileInternalLink';
import { useIsMobile } from 'hooks/use-size-class';
import Link from 'components/link';

import styles from './style.module.scss';
import { testIds } from 'constants/test-constants';

export type LinkDataType = {
  title: string;
  links: {
    label: string;
    link: string;
  }[];
};

interface Props {
  isCrawler?: boolean;
  data: LinkDataType[];
  heading?: string;
  isHomepage?: boolean;
}

const MobileHeadingSeparator = (<div key="mobile-seperator" className={styles['heading-border']}></div>);
const RowSeparator = (<div key="row-seperator" className={styles['row-border']}></div>);
const DesktopHeadingSeparator = (<div key="desktop-seperator" className={styles['row-border']}></div>);
const HomepageHeadingSeparator = (key: string) => (<div key={key} className={styles['column-separator']}><br /></div>);

export default function InternalLinksContainer({ isCrawler = false, data, heading = '', isHomepage = false }: Props) {
  const isMobile = useIsMobile();
  const showHeading = heading.trim().length > 0;
  let groups = data?.map((group, index) => {
    const links = group.links.map((link, index) => (
      <Link key={index} href={link.link}>{link.label}</Link>
    ));
    if (links.length === 0) return;
    return (isMobile
      ? <MobileInternalLink key={index} index={index} isActive={isCrawler} title={group.title} links={links} />
      : <InternalLink key={index} index={index} isActive={isCrawler} title={group.title} links={links} isHomepage={isHomepage} />);
  }).filter(Boolean);


  if (!isMobile && !isHomepage) {
    // if the list is 8 lists long, split like this
    groups.splice(0, 0, DesktopHeadingSeparator);
    if (groups.length > 4) {
      groups.splice(5, 0, RowSeparator);
    }
  }
  if (isMobile) {
    groups?.splice(0, 0, MobileHeadingSeparator);
  }
  else if (isHomepage) {
    // Insert the separators between each dropdown
    groups = groups?.flatMap((value, index, array) =>
      array.length -1 !== index
        ? [value, HomepageHeadingSeparator('HomepageHeadingSeparator' + index)]
        : value
    );
    groups?.splice(0, 0, HomepageHeadingSeparator('HomepageHeadingSeparator100'));
    groups?.splice(groups.length, 0, HomepageHeadingSeparator('HomepageHeadingSeparator101'));
  }

  if (!data?.length) {
    return null;
  }

  return (
    <div className={styles.component} data-testid={testIds.internalLinksContainer} >
      {showHeading && <h3 className={styles.heading}>{heading}</h3>}
      <div className={isHomepage && !isMobile ? styles['layout-container-homepage'] : styles['layout-container-area-page']}>
        {groups}
      </div>
    </div>
  );
}
