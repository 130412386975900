import React, { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import AdvancedSearch from 'components/advanced-search';
import Button, { PRIMARY_THEME, SECONDARY_THEME, TERTIARY_THEME } from 'components/control/button';
import LocationSearch from 'components/location-search';
import FilterIcon from 'components/icon/filter-icon';
import MapIcon from 'components/icon/map-icon';
import useOutsideClickHandler from 'hooks/use-outside-click-handler';
import MapListToggleButtonOnMap, { MAP_VIEW, ViewType } from './map_list_toggle_button';
import BuyOrRentFilterDropdown from './buy_or_rent_filter_dropdown';
import ListingStatusFilterDropdown from './listing_status_filter_dropdown';
import PropertyTypeFilterDropdown from './home_type_filter_dropdown';
import PriceRangeFilterDropdown from './price_range_filter_dropdown';
import BedroomsFilterDropdown, { Bedrooms } from './bedrooms_filter_dropdown';
import { useExperiment } from '@growthbook/growthbook-react';
import { trackEvent } from 'utils/google-tag-manager';
import { sortSearchPredictions } from 'components/suggested-location-dropdown';
import styles from './style.module.scss';
import { searchFilterIds } from 'constants/test-constants';
import { useIsMobile } from 'hooks/use-size-class';
import { ThemeNames } from 'types/themes';
import { useFeaturesContext, useThemeContext } from 'contexts';
import Cookies from 'js-cookie';
import { MLML_AGENT_COOKIE_NAME } from 'constants/cookies';
import MyLinkMyLeadCreate from 'components/my-link-my-lead-create';
import { propertyTypeOptions } from 'utils/select-options';
import { legacyPropertyTypeOptions } from 'utils/select-options';

import type { Filter, PropertyTypeFilter, Status } from 'contexts/preferences/listing-params/types';
import type { PartialDeep } from 'type-fest';
import type SearchPrediction from 'data/search-predictions';
import { getNumberOfPropertyTypesFiltered } from 'utils/listing-query-helper';
import { getNumberOfLegacyPropertyTypesFiltered } from 'utils/listing-query-helper';

//#region Types

export type AreaListingsPageFiltersType = Pick<Filter, 'slug' | 'areaName' | 'rental' | 'status' | 'homeType' | 'priceMin' | 'priceMax' | 'bedrooms'>;

export interface AreaListingsPageFiltersProps {
  filters: AreaListingsPageFiltersType;
  onValueChange: (filters: PartialDeep<AreaListingsPageFiltersType>) => void;
  onSaveSearchButtonClick: () => void;
  onLocationSearchClick: (searchPrediction: SearchPrediction) => void;
  onMapIconClick?: () => void;
  onMoreButtonClick: () => void;
  onMapListToggleButtonClick?: () => void;
  mapListToggleViewType?: ViewType;
}
//#endregion

//#region Constants
const MobileAdvancedSearchClassName = styles['mobile-search'];
const FiltersContainerClassName = styles['filters-container'];
const FiltersLeftContainerClassName = styles['filters-left-container'];
const FiltersRightContainerClassName = styles['filters-right-container'];
const SearchContainerClassName = styles['search-container'];
const PaddedButtonClassName = styles['padded-button'];
const SaveSearchButtonClassName = styles['save-search'];
const MiddleFiltersContainerClassName = styles['middle-filters'];
const MLMLCreateClassName = styles['mlml-create-custom'];
//#endregion

export const AreaListingsPageFilters = ({ filters, onSaveSearchButtonClick, onMapIconClick, onLocationSearchClick, onValueChange, onMoreButtonClick, mapListToggleViewType = MAP_VIEW, onMapListToggleButtonClick }: AreaListingsPageFiltersProps) => {
  const router = useRouter();
  const isOnMap = router.route === '/search';
  const isMobile = useIsMobile();
  const { features } = useFeaturesContext();
  const [isLocationDropdownActive, setIsLocationDropdownActive] = useState(false);
  const [locationQuery, setLocationQuery] = useState('');
  const [searchBarPlaceholder, setSearchBarPlaceholder] = useState<string>();
  const abTestingFiltersButton = useExperiment({ key: 'more-filters', variations: [0, 1]});
  const saveSearchButtonStyle = abTestingFiltersButton.variationId === 1 ? SECONDARY_THEME : TERTIARY_THEME;
  const { themeName } = useThemeContext();
  const showsMLMLCreate = themeName && themeName === ThemeNames.EXP_REALTY_US && !!Cookies.get(MLML_AGENT_COOKIE_NAME);

  const { slug, areaName, rental, status, homeType, priceMin, priceMax, bedrooms } = filters;

  const handleBuyOrRentFilterChange = useCallback((value: boolean) => {
    onValueChange({      
      rental: value, 
      priceMin: null, 
      priceMax: null,
    });
  }, [onValueChange]);

  const handleListingStatusFilterChange = useCallback((value: Status) => {
    if (value !== status) {
      onValueChange({ status: value });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onValueChange]);

  const handlePropertyTypeFilterChange = useCallback((value: PropertyTypeFilter) => {
    if (value !== homeType) {
      onValueChange({ homeType: value });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onValueChange]);

  const handleBedroomsFilterChange = useCallback((value: string) => {
    const isDefaultBedroomsValue = bedrooms === undefined && value === '0+';
    if (value !== bedrooms && !isDefaultBedroomsValue) {
      onValueChange({ bedrooms: value });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onValueChange]);

  const handlePriceRangeFilterChange = useCallback((min: number | null, max: number | null) => {
    if ((min != priceMin) || (max != priceMax)) {
      if (min && max && min > max) {
        onValueChange({ priceMin: min || null, priceMax: min || null });
      } else {
        onValueChange({ priceMin: min || null, priceMax: max || null });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onValueChange]);

  const handleLocationSearchClick = useCallback((searchPrediction: SearchPrediction) => {
    setSearchBarPlaceholder(searchPrediction.description.split(',')[0]);
    onLocationSearchClick(searchPrediction);
    setIsLocationDropdownActive(false);
    setLocationQuery(searchPrediction.description);
  }, [onLocationSearchClick]);

  const handleSearchIconClick = useCallback((searchPredictions: SearchPrediction[]) => {
    if (locationQuery.length >= 3) {
      const suggestedResults = sortSearchPredictions(searchPredictions);
      const flattenedResults = [
        ...suggestedResults.locations,
        ...suggestedResults.listings,
        ...suggestedResults.buildings,
        ...suggestedResults.schools,
      ];
      handleLocationSearchClick(flattenedResults[0]);
    }
  }, [handleLocationSearchClick, locationQuery.length]);

  useEffect(() => {
    if (areaName) {
      setLocationQuery(areaName);
      setSearchBarPlaceholder(areaName.split(',')[0]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areaName]);

  useEffect(() => {
    trackEvent(abTestingFiltersButton.variationId === 1 ? 'XpB_QFBut' : 'XpA_QFBut');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={styles.component}>
        {isMobile && <div className={MobileAdvancedSearchClassName}>
          <AdvancedSearch />
        </div>}
        <div className={FiltersContainerClassName}>
          <div className={FiltersLeftContainerClassName}>
            <div className={SearchContainerClassName} ref={useOutsideClickHandler(() => setIsLocationDropdownActive(false))}>
              <LocationSearch
                locationQuery={locationQuery}
                setLocationQuery={setLocationQuery}
                isLocationDropdownActive={isLocationDropdownActive}
                setIsLocationDropdownActive={setIsLocationDropdownActive}
                onClick={handleLocationSearchClick}
                placeholder={searchBarPlaceholder}
                onSearchIconClick={handleSearchIconClick}
              />
            </div>
            <BuyOrRentFilterDropdown
              rental={rental}
              onValueChange={handleBuyOrRentFilterChange}
            />
            <ListingStatusFilterDropdown
              rental={rental}
              status={status}
              onValueChange={handleListingStatusFilterChange}
            />
            <div className={MiddleFiltersContainerClassName}>
              <PropertyTypeFilterDropdown
                propertyType={homeType}
                propertyTypeFilterLabel={`Home Type (${features.useLegacySearchFilter ? getNumberOfLegacyPropertyTypesFiltered(homeType) : getNumberOfPropertyTypesFiltered(homeType)})`}
                onValueChange={handlePropertyTypeFilterChange}
                propertyTypeList={features.useLegacySearchFilter ? legacyPropertyTypeOptions : propertyTypeOptions} 
              />
              <PriceRangeFilterDropdown
                min={priceMin}
                max={priceMax}
                rental={rental}
                onValueChange={handlePriceRangeFilterChange}
              />
              <BedroomsFilterDropdown
                bedrooms={bedrooms as Bedrooms}
                onValueChange={handleBedroomsFilterChange}
              />
            </div>
            <span className={PaddedButtonClassName}>
              <Button
                Icon={FilterIcon}
                theme={PRIMARY_THEME}
                label="More Filters"
                onClick={onMoreButtonClick}
                testId={searchFilterIds.moreButtonMapPage}
              />
            </span>
            {isOnMap && !showsMLMLCreate &&
              <div className={SaveSearchButtonClassName}>
                <Button
                  theme={saveSearchButtonStyle}
                  label="Save Search"
                  onClick={onSaveSearchButtonClick}
                  testId={searchFilterIds.saveSearchButtonMapPage}
                />
              </div>
            }
            {isOnMap && showsMLMLCreate && <MyLinkMyLeadCreate className={MLMLCreateClassName} />}
          </div>
          <div className={FiltersRightContainerClassName}>
            {isOnMap && onMapListToggleButtonClick &&
              <MapListToggleButtonOnMap
                slug={slug}
                viewType={mapListToggleViewType}
                onClick={onMapListToggleButtonClick}
                isMobile={isMobile}
              />
            }
            {!isOnMap &&
              <Button
                Icon={MapIcon}
                theme={SECONDARY_THEME}
                label="Map View"
                onClick={onMapIconClick}
                testId={searchFilterIds.mapButtonAreaPage}
              />
            }
          </div>
        </div>
      </div>
    </>
  );
};
