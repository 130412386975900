// This is the simple solution we have opted for now while
// the number of priority feeds is still low. Eventually
// this will be engineered into something more robust
const priorityFeeds: ReadonlyArray<number> = [
  31135, // FMLS state id
] as const;

// move the priority feeds to the front of the array, if they are found
const prioritizeFeeds = (providers: Array<number | null>) => {
  providers.forEach((provider, i) => {
    if (provider !== null && priorityFeeds.includes(provider)) {
      providers.splice(i, 1);
      providers.unshift(provider);
    }
  });
  return providers;
};
export default prioritizeFeeds;
