export function formatNumberWithSuffix(num: number, isCurrency = true) {
  const stringifiedNumber = num.toString();
  let formattedNumber;

  if (num < 10) {
    formattedNumber = '$' + stringifiedNumber.substring(0, 1) + '.00';
  } else if (num < 100) {
    formattedNumber = '$' + stringifiedNumber.substring(0, 2);
  } else if (num >= 1000 && num < 10_000) {
    formattedNumber = stringifiedNumber.substring(0, 1) + '.' + stringifiedNumber.substring(1, 3) + 'K';
  } else if (num >= 10_000 && num < 100_000) {
    formattedNumber = stringifiedNumber.substring(0, 2) + '.' + stringifiedNumber.substring(2, 3) + 'K';
  } else if (num >= 100_000 && num < 1_000_000) {
    formattedNumber = stringifiedNumber.substring(0, 3) + 'K';
  } else if (num >= 1_000_000 && num < 10_000_000) {
    formattedNumber = stringifiedNumber.substring(0, 1) + '.' + stringifiedNumber.substring(1, 3) + 'M';
  } else if (num >= 10_000_000 && num < 100_000_000) {
    formattedNumber = stringifiedNumber.substring(0, 2) + '.' + stringifiedNumber.substring(2, 3) + 'M';
  } else if (num >= 100_000_000) {
    formattedNumber = stringifiedNumber.substring(0, 3) + 'M';
  } else {
    formattedNumber = stringifiedNumber;
  }

  if (!isCurrency) {
    formattedNumber = formattedNumber.replace('$', '').replace('.00', '');
  }

  return formattedNumber;
}
