export default function formatPriceRange(min: number | string | null, max: number | string | null) {
  if (min && max) {
    return `${formatPrice(min)} - ${formatPrice(max)}`;
  } else if (min && !max) {
    return `Above ${formatPrice(min)}`;
  } else if (!min && max) {
    return `Below ${formatPrice(max)}`;
  } else {
    return 'Any Price';
  }
}

function formatPrice(value: number | string) {
  if (typeof value === 'number') {
    value = value.toFixed();
  }
  value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  value = value.replace(/[^\d,]/g, '');
  const prefix = value.slice(0, 4).replace(',', '.').replace(/(\.[^0]*?)0+$/, '$1').replace(/\.$/, '');
  const partsCount = value.split(',').length;
  const suffix = partsCount === 1 ? '' : (partsCount === 2 ? 'K' : 'M');
  return `${prefix}${suffix}`;
}
