import getAddressForLocationSearch from './get-address-for-location-search';
import getMostSpecificAddressRegion from './get-most-specific-address';
import { getPositionFromDescription } from './google-maps/getPositionFromDescription';

import type Address from 'data/addresses';
import type { NextRouter } from 'next/router';
import type ListingParams from 'contexts/preferences/listing-params';

export default async function clickMapView(listingParams: ListingParams, addresses: readonly Address[], router: NextRouter, useUsListings?: boolean) {
  navigateToMapView(listingParams, addresses, router.push.bind(router), useUsListings);
}

export async function navigateToMapView(listingParams: ListingParams, addresses: readonly Address[], push: NextRouter['push'], useUsListings?: boolean) {
  const addressRegion = getMostSpecificAddressRegion(addresses);
  if (addressRegion?.addressType === 'province') {
    listingParams.setZoom(7);
  } else {
    listingParams.setZoom(14);
  }
  if (addressRegion) { 
    const formattedAddressSearchQuery = getAddressForLocationSearch(addressRegion);
    const data = await getPositionFromDescription(formattedAddressSearchQuery, useUsListings).catch(() => {
      window.location = '/search' as unknown as Location;
      return;
    });
    if (data) {
      const { latitude, longitude } = data;
      listingParams.setLatitude(latitude);
      listingParams.setLongitude(longitude);
      listingParams.setAreaName(formattedAddressSearchQuery.split(',')[0]);
    }
  }
  push('/search');
}
