import type Address from 'data/addresses';

export default function getMostSpecificAddressRegion(addresses: readonly Address[]) {
  const addressTypes = addresses.map(address => address.addressType);
  let highestSpecificRegion = '';
  if (addressTypes.includes('neighbourhood')) {
    highestSpecificRegion = 'neighbourhood';
  } else if (addressTypes.includes('place-name')) {
    highestSpecificRegion = 'place-name';
  } else if (addressTypes.includes('sub-division')) {
    highestSpecificRegion = 'sub-division';
  } else if (addressTypes.includes('province')) {
    highestSpecificRegion = 'province';
  }
  return addresses.find(address => address.addressType === highestSpecificRegion);
}
